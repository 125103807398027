import React, {useState} from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import healthIcon from '../images/healthcare-icon-solid-white.png'
import { graphql, useStaticQuery } from "gatsby"


const isBrowser = typeof window !== "undefined"

const ContactMap = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
          pharmacyMarkers {
          name
          lat
          long
          province
          city
          streetName
          streetLvl
        }
       colors {
          primaryColor
          secondaryColor
        }
      }
    }
  `)
  const { pharmacyMarkers } = data.pharmacy
  // const testMarkers = [
  //   {
  //     lat: -34.58199390000001,
  //     long: -58.40286469999999,
  //     name: "Sede Central",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 2102,
  //     streetName: "AV DEL LIBERTADOR",
  //   },
  //   {
  //     lat: -34.583,
  //     long: -58.405,
  //     name: "Sede 1",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 2300,
  //     streetName: "Calle 1",
  //   },
  //   {
  //     lat: -34.580,
  //     long: -58.405,
  //     name: "Sede 2",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 4300,
  //     streetName: "Calle 2",
  //   },
  //   {
  //     lat: -34.580,
  //     long: -58.408,
  //     name: "Sede 3",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 1234,
  //     streetName: "Calle 3",
  //   },
  //   {
  //     lat: -34.577,
  //     long: -58.408,
  //     name: "Sede 4",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 3244,
  //     streetName: "Calle 4",
  //   },
  //   {
  //     lat: -34.576,
  //     long: -58.411,
  //     name: "Sede 5",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 3142,
  //     streetName: "Calle 5",
  //   },
  //   {
  //     lat: -34.583,
  //     long: -58.402,
  //     name: "Sede 6",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 543,
  //     streetName: "Calle 6",
  //   },

  // ]
  const centers = pharmacyMarkers.map((marker) => {
    return {
      lat: marker.lat,
      lng: marker.long
    }
  })

  const containerStyle = {
    width: '100%',
    minHeight: '100%'
  };

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
      {
        "stylers": [{
          "saturation": -100
        }]
      },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.government",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.school",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels",

        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.local",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.station.bus",
        stylers: [{ visibility: "off" }],
      },
    ]
  }

  const [activeMarker, setActiveMarker] = useState(null)
  const onMarkerClick = (idx) => {
    setActiveMarker(idx)
  }


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCaZQmkoYdYLQzcLAUcIg2ak002aowQaEg"
  })

  const onLoad = React.useCallback(function callback(map) {

    const bounds = isBrowser ? new window.google.maps.LatLngBounds(centers[0]) : null

    centers.map(center => (
      bounds.extend(center)
    ))
    map.fitBounds(bounds)
    var listener = window.google.maps.event.addListener(map, "idle", function () {
      if (map.getZoom() > 16) map.setZoom(16);
      window.google.maps.event.removeListener(listener);
    });
  }, [centers])

  if (!isLoaded) return null
  return (
    <div className="map_height_500">
          <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={containerStyle}
        onClick={() => setActiveMarker(null)}
        options={mapOptions}
      >
        {pharmacyMarkers?.map((marker, idx) => {
          const isActive = activeMarker === idx
          return <Marker
            key={`marker-${marker.title}${idx}`}
            position={{
              lat: marker.lat,
              lng: marker.long
            }}
            onClick={() => onMarkerClick(idx)}
            // icon={{
            //   url: `https://chart.googleapis.com/chart?chst=d_map_pin_icon&chld=medical|${activeMarker === idx ? 'FFFFFF' : 'FFFFFF'}`,
            // }}
            icon={
              {
                url: healthIcon,
                scaledSize: { width: isActive ? 34 : 24, height: isActive ? 34 : 24 }
              }
            }
          />
        }
          )}

        {pharmacyMarkers?.map((marker, idx) =>
          activeMarker === idx ?
            <InfoWindow
              key={`info-${marker.title}${idx}`}
              onCloseClick={() => setActiveMarker(null) }
              position={{
                lat: marker.lat,
                lng: marker.long
              }}
              options={{ pixelOffset: { width: 0, height: -34 } }}
            >
              <div >
                <span><b>{marker.name}</b><br></br>
                  {`${marker.streetName} ${marker.streetLvl}`}<br></br>
                  {marker.province}
                </span>
              </div>
            </InfoWindow>
            : false
        )}
        


        { /* Child components, such as markers, info windows, etc. */}
        <>
        </>
      </GoogleMap>
    </div>


  );
};

export default ContactMap;
