import React, {useState, useEffect} from "react";
import { graphql, useStaticQuery } from "gatsby"
import Reaptcha from "reaptcha";
import axios from "axios"


const ContactThree = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        contactMeans {
          phones
          mobile_phone
          email        }
          pharmacyMarkers {
          name
          lat
          long
          province
          city
          streetName
          streetLvl
          is_main
          contact_data {
            emails
            phones
            mobile_phones
          }
        }
        env {
          APISERVER
          TENANTID
        }
      }
    }
  `)

  const { pharmacyMarkers } = data.pharmacy
  const [subscribing, setSubscribing] = useState(false)
  const [responseState, setResponseState] = useState({
    success: false,
    successMsg: "Se envió la solicitud correctamente",
    error: false,
    errorMsg: "Ha ocurrido un error",

  }
)
  const { contactMeans, env } = data.pharmacy
  const { phones, email } = contactMeans
  const [contactData, setContactData] = useState({name:'',email:'', phone:'', message:'', captchaVerified:false})
  const [canSubscribe, setCanSubscribe] = useState(false)

  const resetForm = () => {
    setContactData({ ...contactData, name: '', email: '', phone: '', message: '' })
  } 

  const handleSubscribe = () => {
    const { name, email, phone, message }=contactData
    setSubscribing(true)
    axios
      .post(
        `${env.APISERVER}/core/send_email/contact/${env.TENANTID}`,
        { name, email, phone, message }
      )
      .then(response => {
        setResponseState({
          error:false,
          success: true,
          successMsg: "Su consulta ha sido enviada satisfactoriamente.",
        })
        resetForm();
        setSubscribing(false);

      })
      .catch(error => {
        setResponseState({
          error: true,
          success: false,
          // errorMsg: error.response.data,
          errorMsg: "Ocurrió un error.",
        })
        setSubscribing(false);

      })
  }

  useEffect(() => {
    setCanSubscribe(!subscribing && contactData.captchaVerified && !!contactData.name && !!contactData.email  && !!contactData.message)
  }, [contactData, subscribing])

  return (
    <section className={`contact_form_area contact_us section_padding ${!responseState.success && !responseState.error ? 'contact_us_extra_padding_100' :'contact_us_extra_padding_50'}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Contáctanos</h4>
                <h1>Estamos para ayudarte</h1>
              </div>
              <p>
                No dudes en ponerte en contacto con nosotros por cualquier consulta.
              <br/>
                Nuestro equipo recibirá su solicitud y le responderá a la mayor brevedad posible.
              </p>
              <div className="contact_location_box">
                 <div className="contact_location_map contact_location_call justify-content-center">
                  <span className="icon-contact_call pe-4"></span>
                  <p className="ps-5">
                    <a href={`mailto:${email}`}
                    >{email}
                      </a>
                    <br />
                    {phones[0]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-right contact-right-style-2 responsive_mt">
                <input
                  className="half_width input_m_right"
                  type="text"
                  name="name"
                  placeholder="Nombre y Apellido *"
                  value={contactData.name}
                  onChange={(e) => setContactData({ ...contactData, name: e.target.value })}
                />
                <input
                  className="half_width"
                  type="EMAIL"
                  name="email"
                  placeholder="Email *"
                  value={contactData.email}
                  onChange={(e) => setContactData({ ...contactData, email: e.target.value })}

                />
                <input
                  type="tel"
                  name="tel"
                  placeholder="Teléfono"
                  value={contactData.phone}
                  onChange={(e) => setContactData({ ...contactData, phone: e.target.value })}
                />
                <textarea
                  name="content"
                  id="content"
                  cols="20"
                  rows="5"
                  placeholder="Mensaje *"
                  value={contactData.message}
                  onChange={(e) => setContactData({ ...contactData, message: e.target.value })}

                ></textarea>
                <div className="d-flex flex-wrap align-items-center justifiy-content-center-mobile">

                <Reaptcha
                  className="mb-3"
                  sitekey="6LeeyzscAAAAAP83mITcGIFEreUNXF8wmSREAEmR"
                  hl='es-419'
                  onVerify={() => setContactData({ ...contactData, captchaVerified: true })}
                  onExpire={() => setContactData({ ...contactData, captchaVerified: false })}

                >
                  </Reaptcha>
                  <div className="d-flex justify-content-center flex-fill mb-3">

                    <button
                    className={`ms-2 w-100 reCaptcha_height btn-yellow${canSubscribe ? '' : '-disabled'}`}
                      type='button'
                      onClick={handleSubscribe}
                      disabled={!canSubscribe}
                    >
                      ENVIAR AHORA
                  </button>
                </div>

                </div>
                {responseState.success && (
                  <span style={
                    {
                      display: 'flex',
                      width: '100%',
                      color: 'green',
                      backgroundColor: '#DEF0D8',
                      textAlign: 'center',
                      justifyContent: 'center',
                      // justifyContent: 'flex-start',
                      padding: '10px 30px',
                      fontSize: '14px',
                    }
                  } className="success1 mb-3" id="success">
                    {responseState.successMsg}
                  </span>
                )}
                {responseState.error && (
                  <span style={
                    {
                      display: 'flex',
                      width: '100%',
                      color: 'red',
                      backgroundColor: '#f3cbd6',
                      textAlign: 'center',
                      justifyContent: 'center',
                      // justifyContent: 'flex-start',
                      padding: '10px 30px',
                      fontSize: '14px',
                    }
                  } className="success1 mb-3" id="success">
                    {responseState.errorMsg}
                  </span>
              )}
              {(phones[0] || pharmacyMarkers.find(pharmacy => pharmacy.is_main)?.contact_data?.phones[0]) &&
                <a href={`tel:+${(phones[0] ?? pharmacyMarkers.find(pharmacy => pharmacy.is_main)?.contact_data?.phones[0]).replace(/ /g, '').replace(/-/g, '').replace(/\+/g, '')}`}
                  className={`mb-5 w-100 text-center btn-yellow showOnMobile`}
                >                     <i className="icon-phone-call me-2" style={{ fontSize: "1.5rem" }}

                  />

                  LLAMAR AHORA
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactThree;
